import { inject, Injectable, isDevMode } from '@angular/core';
import i18next from 'i18next';
import HttpBackend from 'i18next-http-backend';
import { LoggingService } from '../errorlogger/logging.service';

@Injectable({
  providedIn: 'root',
})
export class TranslationService {
  private initialised = false;
  private loggingService = inject(LoggingService);

  initialise(): Promise<void> {
    return new Promise((resolve, reject) => {
      i18next
        .use(HttpBackend)
        .init({
          fallbackLng: 'en', // Default language
          interpolation: { escapeValue: false }, // No HTML escaping
          defaultNS: 'translation',
          backend: {
            loadPath: 'assets/locales/{{lng}}/{{ns}}.json', // Path to language files
          },
          debug: isDevMode(), // Enable debugging in dev mode
        })
        .then(() => {
          this.initialised = true;
          resolve();
        })
        .catch((error) => {
          this.loggingService.error('Failed to initialize i18next:', error);
          reject(error);
        });
    });
  }

  changeLanguage(lang: string): Promise<void> {
    return new Promise((resolve, reject) => {
      i18next
        .changeLanguage(lang)
        .then(() => {
          this.loggingService.info(`Language changed to ${lang}`);
          resolve();
        })
        .catch((error) => {
          this.loggingService.error(`Failed to change language to ${lang}:`, error);
          reject(error);
        });
    });
  }

  isInitialized(): boolean {
    return this.initialised;
  }

  tt(key: string): string {
    return this.initialised ? i18next.t(key) : '';
  }
  t(key: string): string {
    // if (!this.initialised) {
    //   console.warn('TranslationService is not initialized yet.');
    //   return 'Service not initialized';
    // }
  
    const translationNamespaces = i18next.store.data[i18next.language]['translation']; // Replace 'en' with the actual language code
    // console.log('object :>> ', translationNamespaces);
    if (translationNamespaces['error'] && key in translationNamespaces['error']) {
      return translationNamespaces['error'][key];
    }
    if (translationNamespaces['menu'] && key in translationNamespaces['menu']) {
      return translationNamespaces['menu'][key];
    }
  
    return 'Key not found';
  }
}
